import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Clothes Store App Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/sport-clothing-shopping-app/"
    metaDescription="Are you looking for a mobile app shopping template for your design project? Basket is the e-commerce app template for you! Customize online now."
    description="
    h2:Introducing Basket: a mobile clothing store app template
    <br/>
    Build your online clothing store using Basket, Uizard's mobile clothes shopping app template. Among all our <a:https://uizard.io/templates/>UI design templates</a> in Uizard, Basket is a crowd favorite as it is flexible, stylish, and functional.
    <br/>
    h3:A beautiful mobile shopping app template, focused on selling
    <br/>
    Are you looking for an app template to start selling your products? Then Basket is the mobile shopping app template for you. Designed to streamline your design process, Uizard templates empower you to create stunning, <a:https://uizard.io/mockups/>UI mockups</a> for your mobile shopping store project. 
    <br/>
    h3:Mockup templates with Uizard are fully customizable
    <br/>
    As well as having full control of the content, you can also fully customize Basket's architecture and theme. Sing up to Uizard today and start your next digital product design flow.
    "
    pages={[
      "A sign-up page",
      "A log-in page",
      "A homepage",
      "A search page with all categories in the app template",
      "A profile page",
      "A product details page",
      "A page showing what the customer has on his or her cart",
      "A customer account and info page",
      "A delivery info page",
      "A payment and credit card page",
      "All the pages required for a customer checkout flow",
    ]}
    projectCode="4ol8n34E4KUjgjVwrEL0"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of Basket sport clothing shopping app: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of Basket sport clothing shopping app: user shopping cart page"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of Basket sport clothing shopping app: payment page"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of Basket sport clothing shopping app: profile page"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of Basket sport clothing shopping app template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(relativePath: { eq: "templates/basket/basket-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(relativePath: { eq: "templates/basket/basket-cart.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(relativePath: { eq: "templates/basket/basket-payment.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(relativePath: { eq: "templates/basket/basket-profile.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(relativePath: { eq: "templates/basket/basket-summary.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
